/* eslint-disable react/no-danger */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import * as css from './Checkbox.scss';

type Props = {
	input: {
		name: string,
		onChange: Function,
		checked?: boolean,
	},
	meta: {
		touched?: boolean,
		error?: string,
		submitFailed?: boolean,
	},
	className?: string,
	label: React.Element<*>,
};

export default class Checkbox extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
	};

	render() {
		const { className, label, ...rest } = this.props;
		const isActive = _.get(this.props, 'input.checked');
		const isErrorShown = (rest.meta.error && rest.meta.submitFailed) || (rest.meta.error && rest.meta.touched);

		return (
			<label className={classNames(css.checkbox, isActive && css.active, isErrorShown && css.error, className)}>
				<div className={css.control} />
				<p>{label}</p>
				<input {...this.props.input} type="checkbox" />
			</label>
		);
	}
}
