// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import CoversList from './CoversList';

const mapState = (state: ClientStore) => ({
	covers: _.orderBy(_.get(state, 'catalog.data', []), 'timestamp'),
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(CoversList);
