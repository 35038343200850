// @flow
import { connect } from 'react-redux';
// import _ from 'lodash';
import VodSection from './VodSection';

const mapState = (state: ClientStore) => ({
	items: state.videos.data.items,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(VodSection);
