// @flow

import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Responsive } from 'client/components/common';
import cn from 'classnames';

import { Icon } from 'client/components/common/Icon';
import ArticleItem from './ArticleItem';
import css from './Articles.scss';

type Props = {|
	items: any[],
|};

export default function Articles(props: Props) {
	const { items } = props;
	const [activeIndex, setActiveIndex] = useState(0);
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	// const shuffledItems = _.shuffle(items);
	const chunkedMobileItems = _.chunk(items, 2);

	return (
		<div className={css.articlesSection}>
			<div className={css.header}>
				<h4 className={css.title}>כתבות מיוחדות</h4>
				<a
					href="https://www.ynet.co.il/laisha/category/41472"
					target="_blank"
					rel="noopener noreferrer"
					className={css.link}
				>
					לכל הכתבות
				</a>
			</div>
			<div className={css.items}>
				{!isMobile ? (
					<>
						{_.map(items, item => {
							return <ArticleItem item={item} key={item.id} />;
						})}
					</>
				) : (
					<Swiper
						className={css.swiper}
						slidesPerView="auto"
						spaceBetween={20}
						pagination
						navigation={{
							prevEl: navigationPrevRef.current,
							nextEl: navigationNextRef.current,
						}}
						dir="rtl"
						onActiveIndexChange={sw => setActiveIndex(sw.activeIndex)}
						onSwiper={swiper => {
							// Delay execution for the refs to be defined
							setTimeout(() => {
								// Override prevEl & nextEl now that refs are defined
								swiper.params.navigation.prevEl = navigationPrevRef.current;
								swiper.params.navigation.nextEl = navigationNextRef.current;

								// Re-init navigation
								swiper.navigation.destroy();
								swiper.navigation.init();
								swiper.navigation.update();
							}, 50);
						}}
						// onActiveIndexChange={onSlideChange}
						// onSwiper={sw => setSwiper(sw)}
					>
						{_.map(chunkedMobileItems, (item, index: number) => {
							return (
								<SwiperSlide className={css.swiperSlide} key={`slide-${index + 1}`}>
									{_.map(item, i => {
										return <ArticleItem item={i} key={i.id} />;
									})}
								</SwiperSlide>
							);
						})}
						<div ref={navigationNextRef} className={css.nextArrow}>
							<Icon type="right-arrow" color="#000" />
						</div>
						<div
							ref={navigationPrevRef}
							className={cn(css.prevArrow, activeIndex !== 0 ? css.visible : '')}
						>
							<Icon type="right-arrow" className={css.rightIcon} color="#000" />
						</div>
					</Swiper>
				)}
			</div>
		</div>
	);
}
