// @flow
import { connect } from 'react-redux';
// import _ from 'lodash';
import Leads from './Leads';

const mapState = (state: ClientStore) => ({});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Leads);
