// @flow

export const GET_ITEMS = {
	START: 'GET_ITEMS.START',
	SUCCESS: 'GET_ITEMS.SUCCESS',
	FAIL: 'GET_ITEMS.FAIL',
};

export const GET_CATALOG_ITEMS = {
	START: 'GET_CATALOG_ITEMS.START',
	SUCCESS: 'GET_CATALOG_ITEMS.SUCCESS',
	FAIL: 'GET_CATALOG_ITEMS.FAIL',
};

export const GET_GALLERY_ITEMS = {
	START: 'GET_GALLERY_ITEMS.START',
	SUCCESS: 'GET_GALLERY_ITEMS.SUCCESS',
	FAIL: 'GET_GALLERY_ITEMS.FAIL',
};

export const GET_TIMELINE_ITEMS = {
	START: 'GET_TIMELINE_ITEMS.START',
	SUCCESS: 'GET_TIMELINE_ITEMS.SUCCESS',
	FAIL: 'GET_TIMELINE_ITEMS.FAIL',
};

export const GET_SETTINGS = {
	START: 'GET_SETTINGS.START',
	SUCCESS: 'GET_SETTINGS.SUCCESS',
	FAIL: 'GET_SETTINGS.FAIL',
};

export const CREATE_ITEM = {
	START: 'CREATE_ITEM.START',
	SUCCESS: 'CREATE_ITEM.SUCCESS',
	FAIL: 'CREATE_ITEM.FAIL',
};

export const GET_BANNERS = {
	START: 'GET_BANNERS.START',
	SUCCESS: 'GET_BANNERS.SUCCESS',
	FAIL: 'GET_BANNERS.FAIL',
};

export const GET_CURRENT_COVER = {
	START: 'GET_CURRENT_COVER.START',
	SUCCESS: 'GET_CURRENT_COVER.SUCCESS',
	FAIL: 'GET_CURRENT_COVER.FAIL',
};
