/* eslint-disable react/no-danger */
// @flow

import React from 'react';
import cn from 'classnames';
import closeImg from 'assets/client/PopUp/close.png';
import css from './PopUp.scss';

type Props = {
	isVisiblePopUp: boolean,
	closePopUp: () => void,
	title: string,
	date?: string,
	content: string,
};
export default function PopUp(props: Props) {
	const { isVisiblePopUp, closePopUp, title, date, content } = props;
	return (
		<div className={cn(css.popUp, isVisiblePopUp && css.visible)}>
			<div className={css.innerWindow}>
				<div className={css.closeButtonWrapper}>
					<img src={closeImg} className={css.closeButton} alt="Close" onClick={closePopUp} />
				</div>
				<div className={css.contentWrapper}>
					<div className={css.innerContentWrapper}>
						<p className={css.title}>{title}</p>
						<p className={css.date}>{date}</p>
						<p dangerouslySetInnerHTML={{ __html: content }} className={css.content}></p>
					</div>
				</div>
			</div>
		</div>
	);
}

PopUp.defaultProps = {
	date: '',
};
