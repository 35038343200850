import React from 'react';
import bg from 'assets/client/Leads/bg.jpg';
import bgMobile from 'assets/client/Leads/leads-bg-mobile.png';
import logo from 'assets/client/Leads/site-logo.png';
import { Responsive } from 'client/components/common';
// import LeadsForm from './LeadsForm';
import css from './Leads.scss';

export default function Leads() {
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const desktopTitlePart1 = `לקבלת גיליון`;
	const desktopTitlePart2 = `מתנה`;
	// const mobileTitle = `תרצי להתפנק במינוי `;
	// const title = isDesktop ? desktopTitle : mobileTitle;
	const subtitle = `עד הבית וללא התחייבות \n`;
	const linkLabel = 'לחצו כאן';
	const link = 'https://magazine.ynet.co.il/laisha_free_long_archiv';

	return (
		<div className={css.leads} style={{ backgroundImage: `url(${isDesktop ? bg : bgMobile})` }}>
			<div className={css.titleWrapper}>
				<h4 className={css.title}>
					<span className={css.titleFirstPart}>{desktopTitlePart1}</span>
					<img src={logo} alt="Logo" className={css.logo} />
					<span className={css.titleSecondPart}>{desktopTitlePart2}</span>
				</h4>
				{/* <img src={logo} alt="Logo" className={css.logo} />
				<h4 className={css.title}>{title}</h4> */}
			</div>
			<p className={css.subTitle}>{subtitle}</p>

			<a href={link} className={css.externalLink} target="_blank" rel="noopener noreferrer">
				{linkLabel}
			</a>
			{/* <LeadsForm /> */}
		</div>
	);
}
