// @flow

import * as React from 'react';
import classNames from 'classnames';

import { share } from 'client/utils/share';
// import { Responsive } from 'client/components/common/Responsive';
// import { events } from 'common/utils/ga';
import { IS_DEVEL } from 'common/constants';
import { dataLayerEvents } from 'common/utils/gtag';
import { SHARE_CONSTANTS } from 'client/constants';
import { Icon } from '../Icon';

import * as css from './Socials.scss';

type Props = {
	className?: string,
	minified?: boolean,
	options?: {
		url?: string,
	},
	color?: string,
	isBold?: boolean,
};

export default class Socials extends React.Component<Props> {
	static defaultProps = {
		className: '',
		minified: false,
		options: {},
		color: '#fff',
		isBold: false,
	};

	onShare = (e: SyntheticEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		const type = e.currentTarget.dataset.id;
		const { options } = this.props;
		// events.onShareClick(type, this.props.shareCategory);
		// share(type, options);
		if (!IS_DEVEL) {
			dataLayerEvents.onShareCoverBtnClick(type);
			share(type, options);
		}
	};

	render() {
		const { className, minified, color, isBold } = this.props;
		// const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
		const instagramLink = 'https://www.instagram.com/laishamag';
		const facebookLink = 'https://www.facebook.com/LaishaMagazine';

		return (
			<div className={classNames(css.socials, minified && css.minified, className)}>
				{minified ? (
					<div className={css.socialLinks}>
						<a
							href={instagramLink}
							target="_blank"
							rel="noreferrer"
							className={css.link}
							onClick={() => dataLayerEvents.onSocialLinkClick('Go To Laisha_instagram_account')}
						>
							<Icon type="instagram" />
						</a>
						<a
							href={facebookLink}
							target="_blank"
							rel="noreferrer"
							className={css.link}
							onClick={() => dataLayerEvents.onSocialLinkClick('Go To Laisha_Facebook_account')}
						>
							<Icon type="facebook" />
						</a>
					</div>
				) : (
					<div className={css.overflowWrap}>
						<button
							type="button"
							className={classNames(css.socialButton, isBold && css.isBold)}
							data-id={SHARE_CONSTANTS.WHATSAPP}
							onClick={this.onShare}
						>
							<Icon type="whatsapp" className={css.iconWhatsapp} color={color} />
						</button>
						<button
							className={classNames(css.socialButton, isBold && css.isBold)}
							type="button"
							data-id={SHARE_CONSTANTS.TWITTER}
							onClick={this.onShare}
						>
							<Icon type="twitter" className={css.iconTwitter} color={color} />
						</button>
						<button
							className={classNames(css.socialButton, isBold && css.isBold)}
							type="button"
							data-id={SHARE_CONSTANTS.FACEBOOK}
							onClick={this.onShare}
						>
							<Icon type="facebook" className={css.iconFacebook} color={color} />
						</button>
						<button
							className={classNames(css.socialButton, isBold && css.isBold)}
							type="button"
							data-id={SHARE_CONSTANTS.EMAIL}
							onClick={this.onShare}
						>
							<Icon type="email" className={css.iconEmail} color={color} />
						</button>
					</div>
				)}
			</div>
		);
	}
}
