// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import Articles from './Articles';

const mapState = (state: ClientStore) => ({
	items: _.orderBy(state.articles.data.items, 'orderData', 'desc'),
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Articles);
