/* eslint-disable react/no-danger */
// @flow
import React from 'react';
import { COVER_PAGE_ROUTE } from 'client/constants';

import css from './Timeline.scss';

type Props = {
	cover: string,
	content: string,
	year: string,
	textColor?: string,
	title: string,
	id: string,
	modelName: string,
};

export default function TimelineItem(props: Props) {
	const { cover, year, content, textColor, title, id, modelName } = props;
	return (
		<div className={css.timelineItem}>
			{/* eslint-disable-next-line react/jsx-no-target-blank */}
			<a
				href={COVER_PAGE_ROUTE.replace(':id', id)}
				target="_blank"
				title={title || modelName}
				className={css.coverImageWrap}
			>
				<img src={cover} alt={title || modelName} className={css.coverImage} />
			</a>
			<div className={css.textContent}>
				<h4 className={css.title}>מסע על ציר הזמן</h4>
				<span className={css.year} style={{ color: textColor }}>
					{year}
				</span>
				<div className={css.content} dangerouslySetInnerHTML={{ __html: content }}></div>
			</div>
		</div>
	);
}

TimelineItem.defaultProps = {
	textColor: '#dc2179',
};
