// @flow
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import searchIcon from 'assets/client/Catalog/icon-search.svg';
import { IS_DEVEL } from 'common/constants';
import { dataLayerEvents } from 'common/utils/gtag';

import * as css from './Search.scss';

type Props = {
	className?: string,
	onSearch: Function,
	autocompleteArray: Array<string | number>,
};

type State = {
	searchValue: string,
	sugestionsList: Array<{ label: string }>,
};

class Search extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
	};

	state = {
		searchValue: '',
		sugestionsList: [],
	};

	onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		//! todo: validate search string
		this.setState({ searchValue: value });

		if (value.length > 0) {
			const suggestArray = _.reduce(
				this.props.autocompleteArray,
				(acc, i, index) => {
					const item = i.toString().toLowerCase();
					const suggest = value.toLowerCase();
					const suggestPosition = item.indexOf(suggest);

					if (item.includes(suggest)) {
						acc = _.reject(acc, el => el.toString().toLowerCase() === item);
						acc.push({ pos: suggestPosition, label: item });
					}

					return acc;
				},
				[]
			);

			const orderedItems = _.orderBy(suggestArray, 'pos', 'asc');
			const filteredItems = _.map(orderedItems, i => _.omit(i, ['pos']));

			this.setState({ sugestionsList: filteredItems });
		} else {
			this.setState({ sugestionsList: [] });
		}
	};

	highlightWrapper = (text: string, word: string) => {
		const index = text
			.toString()
			.toLowerCase()
			.indexOf(word);

		if (word.length > 0) {
			return (
				<div>
					<span>{text.substring(0, index)}</span>
					<span className={css.highlight}>{text.substring(index, index + word.length)}</span>
					<span>{text.substring(index + word.length)}</span>
				</div>
			);
		}
		return text;
	};

	handleSuggestClick = (e: SyntheticEvent<HTMLDivElement>) => {
		const { searchString } = e.currentTarget.dataset;

		this.props.onSearch(searchString);
		this.setState({ searchValue: searchString, sugestionsList: [] });
	};

	handleOnSubmit = (e: SyntheticEvent<>) => {
		e.preventDefault();
		if (!IS_DEVEL) {
			dataLayerEvents.onSearchString(this.state.searchValue);
		}
		this.props.onSearch(this.state.searchValue);
		// this.setState({ searchValue: '' });
	};

	render() {
		const { className } = this.props;
		const { searchValue, sugestionsList } = this.state;
		const placeholder = 'הקלידו מילות חיפוש';

		return (
			<form className={classNames(css.searchForm, className)} onSubmit={this.handleOnSubmit}>
				<div className={classNames(css.searchField, !_.isEmpty(sugestionsList) && css.active)}>
					<input
						className={classNames(css.input)}
						type="text"
						value={searchValue}
						placeholder={placeholder}
						onChange={this.onChange}
					/>

					<img src={searchIcon} alt="search icon" className={css.searchIcon} onClick={this.handleOnSubmit} />

					{!_.isEmpty(sugestionsList) && (
						<div className={css.sugestions}>
							<div className={css.sugestionsInner}>
								<div className={css.sugestionsList}>
									{sugestionsList.map((item, index) => {
										return (
											<div
												className={classNames(css.sugestionItem)}
												key={item.label}
												data-search-string={item.label}
												onClick={this.handleSuggestClick}
											>
												{this.highlightWrapper(item.label, searchValue)}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					)}
				</div>
			</form>
		);
	}
}

export default Search;
