// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import Celebs from './Celebs';

const mapState = (state: ClientStore) => ({
	items: _.get(state, 'celebs.data.items', []),
	covers: _.get(state, 'catalog.data', []),
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Celebs);
