// @flow

import * as React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import _ from 'lodash';
import { Responsive } from 'client/components/common/Responsive';
// import logoSRC from 'assets/client/logo.svg';
import logoNewSRC from 'assets/client/newLogo.svg';
import { ANCORS } from 'client/constants';
import stikyLogoSRC from 'assets/client/sticky-logo.svg';
import { IS_DEVEL } from 'common/constants';
import { dataLayerEvents } from 'common/utils/gtag';
import Socialls from '../Socialls';
import * as css from './Header.scss';

const HEADER_ANCOR_LINKS = [
	{
		image: 'https://ynetlaisha-storage.cdn-il.com/top-navigation/10.jpg',
		link: `#${ANCORS.CATALOG}`,
		// labelD: 'מצאי  את השערים \n האהובים עלייך',
		labelD: 'חפשו את השערים \n האהובים',
		labelM: 'חפשו את השערים האהובים',
		gaLabel: 'Search a Cover',
		vpLabel: 'catalog',
		vpTitle: 'ארכיון שערי לאשה - שערים אהובים',
	},
	{
		image: 'https://ynetlaisha-storage.cdn-il.com/top-navigation/6.jpg',
		link: `#${ANCORS.ARTICLES}`,
		labelD: 'סדרת כתבות \n מיוחדות',
		labelM: 'סדרת כתבות מיוחדות',
		gaLabel: 'Special articles',
		vpLabel: 'articles',
		vpTitle: 'ארכיון שערי לאשה - כתבות מיוחדות',
	},
	{
		image: 'https://ynetlaisha-storage.cdn-il.com/top-navigation/11.jpg',
		link: `#${ANCORS.TIMELINE}`,
		labelD: 'מסע על ציר הזמן\n של שערי לאשה',
		labelM: 'מסע על ציר הזמן של שערי לאשה',
		gaLabel: 'Covers’ Timeline',
		vpLabel: 'timeline',
		vpTitle: 'ארכיון שערי לאשה - ציר הזמן',
	},
	{
		image: 'https://ynetlaisha-storage.cdn-il.com/top-navigation/9.jpg',
		link: `#${ANCORS.VIDEOS}`,
		labelD: 'VOD: מאחורי הקלעים \n של השערים',
		labelM: 'VOD: מאחורי הקלעים של השערים',
		gaLabel: 'VOD',
		vpLabel: 'videos',
		vpTitle: 'ארכיון שערי לאשה - VOD',
	},
	{
		image: 'https://ynetlaisha-storage.cdn-il.com/top-navigation/7.jpg',
		link: `#${ANCORS.CELEBS}`,
		labelD: 'נשים מובילות בוחרות \n שער אהוב',
		labelM: 'נשים מובילות בוחרות שער אהוב',
		gaLabel: 'Celebs choose their fav cover',
		vpLabel: 'celebs',
		vpTitle: 'ארכיון שערי לאשה - נשים מובילות',
	},
];

type Props = {
	className?: string,
	isFixed: boolean,
};

const Header = ({ className, isFixed }: Props) => {
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const location = useLocation();
	const link = '/';

	const clickOnNavigation = (e: SyntheticEvent<HTMLLinkElement>) => {
		const label = e.currentTarget.dataset.name;
		const { vplabel, vptitle } = e.currentTarget.dataset;

		if (!IS_DEVEL) {
			dataLayerEvents.onHeaderNavigationBtnClick(label);
			dataLayerEvents.onPageView(vplabel, vptitle);
		}
	};

	const clickOnSubscribersBtn = () => {
		if (!IS_DEVEL) {
			dataLayerEvents.onSubscribersBtnClick();
		}
	};

	const clickOnLatishaHomeBtn = () => {
		if (!IS_DEVEL) {
			dataLayerEvents.onLatishaHomeBtnClick();
		}
	};
	const headerMenu = () => {
		return _.map(HEADER_ANCOR_LINKS, l => {
			return isFixed ? (
				<SwiperSlide className={css.swiperSlide} key={l.link}>
					<a
						href={`${location.search}${l.link}`}
						data-name={l.gaLabel}
						data-vplabel={l.vpLabel}
						data-vptitle={l.vpTitle}
						onClick={clickOnNavigation}
						className={css.menuLink}
					>
						<div className={css.menuImage} style={{ backgroundImage: `url(${l.image})` }} />
						<p className={css.menuLabel}>{isDesktop ? `${l.labelD}` : `${l.labelM}`}</p>
					</a>
				</SwiperSlide>
			) : (
				<a
					href={`${location.search}${l.link}`}
					data-name={l.gaLabel}
					data-vplabel={l.vpLabel}
					data-vptitle={l.vpTitle}
					onClick={clickOnNavigation}
					className={css.menuLink}
					key={l.link}
				>
					<div className={css.menuImage} style={{ backgroundImage: `url(${l.image})` }} />
					<p className={css.menuLabel}>{isDesktop ? `${l.labelD}` : `${l.labelM}`}</p>
				</a>
			);
		});
	};
	const headerMobileMenu = () => {
		return _.map(HEADER_ANCOR_LINKS, l => {
			return (
				<SwiperSlide className={css.swiperSlide} key={l.link}>
					<a
						href={l.link}
						data-name={l.gaLabel}
						data-vplabel={l.vpLabel}
						data-vptitle={l.vpTitle}
						onClick={clickOnNavigation}
						className={css.menuLink}
					>
						<div className={css.menuImage} style={{ backgroundImage: `url(${l.image})` }} />
						<p className={css.menuLabel}>{isDesktop ? `${l.labelD}` : `${l.labelM}`}</p>
					</a>
				</SwiperSlide>
			);
		});
	};

	return (
		<header className={classNames(css.header, className, isFixed && css.isFixed)}>
			<div className={css.headerInner}>
				<div className={css.top} id="header">
					<div className={css.rightPart}>
						{isDesktop && <Socialls minified />}
						<a
							href="https://www.ynet.co.il/laisha"
							onClick={clickOnLatishaHomeBtn}
							className={css.rightTopButton}
							target="_blank"
							rel="noreferrer"
						>
							לאתר לאשה
						</a>
					</div>
					<h1>
						<a href={link} className={css.logo}>
							<img
								src={logoNewSRC}
								title="ארכיון שערי לאישה"
								alt="ארכיון שערי לאישה"
								className={css.logo}
							/>
						</a>
					</h1>
					<a href={`#${ANCORS.LEADS}`} onClick={clickOnSubscribersBtn} className={css.leftTopButton}>
						גליון לאשה מתנה
					</a>
				</div>
				<div className={css.bottom}>
					{isFixed && <img src={stikyLogoSRC} alt="" className={css.stikyLogo} />}
					<div className={css.menu}>
						{isDesktop ? (
							<>
								{isFixed ? (
									<Swiper className={css.swiper} slidesPerView="auto" freeMode>
										{headerMenu()}
									</Swiper>
								) : (
									headerMenu()
								)}
							</>
						) : (
							<Swiper className={css.swiper} slidesPerView="auto" freeMode>
								{headerMobileMenu()}
							</Swiper>
						)}
					</div>
				</div>
			</div>
		</header>
	);
};

Header.defaultProps = {
	className: '',
};

export { Header };
