// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { isMobileOrTablet } from 'client/components/common/Responsive';

import { COVER_PAGE_ROUTE } from 'client/constants';

import * as css from './CoversList.scss';

type Props = {|
	...EnhancedProps,
	covers: CoverItem[],
|};

class CoversList extends React.PureComponent<Props> {
	transitionCompleteTimeout: TimeoutID;

	isDesktop = !isMobileOrTablet();

	render() {
		const { transitionState, covers } = this.props;
		const groups = _.groupBy(covers, 'year');

		return (
			<div className={classNames(css.coversList, css[transitionState])}>
				{_.map(groups, group => (
					<>
						<div className={css.title}>{group[0].year}</div>
						<div className={css.group}>
							{_.map(group, (c, i) => {
								return (
									<div key={c.id} className={css.item}>
										{/* eslint-disable-next-line react/jsx-no-target-blank */}
										<a
											href={COVER_PAGE_ROUTE.replace(':id', c.id)}
											target="_blank"
											className={css.link}
										>{`${c.title || c.modelName} - ${c.date}`}</a>
									</div>
								);
							})}
						</div>
					</>
				))}
			</div>
		);
	}
}

export default withTransition(CoversList);
