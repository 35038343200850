// @flow

import React, { useState } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { dataLayerEvents } from 'common/utils/gtag';
import { IS_DEVEL } from 'common/constants';
import css from './Timeline.scss';

type Props = {
	items: Array<CoverItem>,
	setIndex: any,
	activeIndex: number,
};
export default function TimelineBottom(props: Props) {
	const { items, setIndex, activeIndex } = props;
	const [isHover, setIsHover] = useState({ index: 0, isHover: false });

	const clickOnDot = (e: SyntheticEvent<HTMLDivElement>) => {
		const { index, year } = e.currentTarget.dataset;
		setIndex(+index);
		if (!IS_DEVEL) {
			dataLayerEvents.onTimelineDotClick(year);
		}
	};

	const yearsDots = (year: string) => {
		switch (year) {
			case '2020':
				return true;
			case '2010':
				return true;
			case '2000':
				return true;
			case '1990':
				return true;
			case '1980':
				return true;
			case '1970':
				return true;
			case '1960':
				return true;
			case '1950':
				return true;
			default:
				return false;
		}
	};

	return (
		<div className={css.timelineBottom}>
			{_.map(items, (item, index) => {
				return !yearsDots(item.year) ? (
					<div
						key={item.id}
						className={cn(css.dot, css.smallDot, activeIndex === index && css.active)}
						style={{
							backgroundColor:
								(isHover.isHover && isHover.index === index) || activeIndex === index
									? items[index].textColor
									: '',
						}}
						onClick={clickOnDot}
						onMouseEnter={() => {
							setIsHover({ index, isHover: true });
						}}
						onMouseLeave={() => {
							setIsHover({ index: '', isHover: false });
						}}
						data-index={index}
						data-year={item.year}
					></div>
				) : (
					<div className={css.bigDotWrapper} key={item.id}>
						<div
							className={cn(css.dot, css.bigDot, activeIndex === index && css.active)}
							style={{
								backgroundColor:
									(isHover.isHover && isHover.index === index) || activeIndex === index
										? items[index].textColor
										: '',
							}}
							onClick={clickOnDot}
							onMouseEnter={() => {
								setIsHover({ index, isHover: true });
							}}
							onMouseLeave={() => {
								setIsHover({ index: '', isHover: false });
							}}
							data-index={index}
							data-year={item.year}
						></div>
						<p className={cn(css.year, activeIndex === index ? css.yearActive : '')}>{item.year}</p>
					</div>
				);
			})}
		</div>
	);
}
