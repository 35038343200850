// @flow
import { connect } from 'react-redux';
// import _ from 'lodash';
import Main from './Main';

const mapState = (state: ClientStore) => ({
	banners: state.banners.data,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Main);
