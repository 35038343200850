/* eslint-disable react/no-danger */
// @flow
import React, { useState } from 'react';
import _ from 'lodash';

import { CAN_USE_DOM } from 'common/constants';
import PopUp from 'client/components/common/PopUp';
import TimelineSlider from './TimelineSlider';
import SliderNavigation from './SliderNavigation';
import css from './TimelineMobile.scss';

type Props = {
	items: Array<CoverItem>,
	activeIndex: number,
	setActiveIndex: any,
};
export default function TimelineMobile(props: Props) {
	const { items, activeIndex, setActiveIndex } = props;
	const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);
	const itemsSize = _.size(items);

	const { timelineContent } = items[activeIndex];
	const editedContent = timelineContent.length > 200 ? timelineContent.slice(0, 200) : timelineContent;
	const openPopUp = () => {
		setIsVisiblePopUp(true);
		if (CAN_USE_DOM && document.body) {
			document.body.style.overflow = 'hidden';
		}
	};

	const closePopUp = () => {
		setIsVisiblePopUp(false);
		if (CAN_USE_DOM && document.body) {
			document.body.style.overflow = '';
		}
	};

	return (
		<div className={css.timelineMobileWrapper}>
			<PopUp
				isVisiblePopUp={isVisiblePopUp}
				closePopUp={closePopUp}
				title={items[activeIndex].modelName}
				date={items[activeIndex].date}
				content={items[activeIndex].timelineContent}
			/>
			<h4 className={css.title}>מסע על ציר זמן</h4>
			<div>
				<div className={css.content}>
					<span className={css.editedContent} dangerouslySetInnerHTML={{ __html: editedContent }} />
					<span className={css.showPopUp} onClick={() => openPopUp()}>
						קראו עוד
					</span>
				</div>
			</div>
			<TimelineSlider items={items} setActiveIndex={setActiveIndex} activeIndex={activeIndex} />
			<p className={css.year}>{items[activeIndex].year}</p>
			<SliderNavigation activeIndex={activeIndex} setActiveIndex={setActiveIndex} maxValue={itemsSize} />
		</div>
	);
}
