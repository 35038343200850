/* eslint-disable max-len */
// @flow
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	FACEBOOK: 'Facebook',
	EMAIL: 'Email',
	TWITTER: 'Twitter',
};

export const COLLECTIONS = {
	SETTINGS: 'settings',
	VIDEO: 'video',
	ARTICLES: 'articles',
	CELEBS: 'celebs',
};

export const ANCORS = {
	CATALOG: 'catalog',
	ARTICLES: 'articles',
	TIMELINE: 'timeline',
	VIDEOS: 'videos',
	CELEBS: 'celebs',
	LEADS: 'leads',
};

export const PLACEHOLDER_TEXTS = {
	name: 'שם מלא',
	phoneNumber: 'טלפון',
	email: 'דוא״ל',
};
