// @flow
import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { IS_DEVEL, CAN_USE_DOM, DEFAULT_IMAGE } from 'common/constants';
import { dataLayerEvents } from 'common/utils/gtag';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Lazy, Scrollbar } from 'swiper';
import { Responsive } from 'client/components/common';

// import { fileLink } from 'common/utils';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';
import css from './CelebsNavigation.scss';

SwiperCore.use([Lazy, Scrollbar]);
type Props = {
	items: CelebItem[],
	activeIndex: number,
	setIndex: any,
};

export default function CelebsNavigation(props: Props) {
	const { items, activeIndex, setIndex } = props;
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const imageParams = isDesktop ? '?fit=clip&w=120&h=120&q=70' : '?fit=clip&w=65&h=65&q=70';

	const clickOnCeleb = (e: SyntheticEvent<HTMLDivElement>) => {
		const { index } = e.currentTarget.dataset;
		setIndex(+index);
		if (!IS_DEVEL) {
			const celebInfo = items[+index];
			if (celebInfo) {
				dataLayerEvents.onCelebClick(celebInfo.name);
			}
		}
	};

	return (
		<div className={css.celebsNavigation} id="celebs-navigation">
			<Swiper
				className={css.swiper}
				// slidesPerView={isDesktop ? 7 : 5}
				slidesPerView="auto"
				centeredSlides={!isDesktop}
				initialSlide={!isDesktop ? 2 : 0}
				scrollbar={{
					hide: true,
					draggable: true,
				}}
				lazy={{ loadPrevNext: true, loadPrevNextAmount: !isDesktop ? 3 : 7 }}
			>
				{_.map(items, (item: CelebItem, index: number) => {
					return (
						<SwiperSlide className={css.swiperSlide} key={item.id}>
							<div data-index={`${index}`} onClick={clickOnCeleb} className={cn(css.itemWrapper)}>
								<div className={cn(css.itemBgWrapper, activeIndex === index ? css.active : '')}>
									<div
										// style={{
										// 	backgroundImage: `url(${
										// 		CAN_USE_DOM ? fileLinkIMGIX(item.smallImg, imageParams) : DEFAULT_IMAGE
										// 	})`,
										// }}
										data-background={
											CAN_USE_DOM ? fileLinkIMGIX(item.smallImg, imageParams) : DEFAULT_IMAGE
										}
										className={cn(css.item, 'swiper-lazy')}
										key={item.id}
									></div>
								</div>
								{isDesktop && <p className={css.name}>{item.name}</p>}
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
}
