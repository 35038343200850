// @flow
import * as React from 'react';
import classNames from 'classnames';
import { dataLayerEvents } from 'common/utils/gtag';
// import { fileLink } from 'common/utils/file-link';
import { CAN_USE_DOM, DEFAULT_IMAGE } from 'common/constants';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';
import { Responsive } from '../Responsive';

import * as css from './Banner.scss';

type Props = {
	className?: string,
	banner: BannersItem,
};

const Banner = ({ className, banner }: Props) => {
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const imageParams = isDesktop ? '?fit=clip&w=1280&h=260&q=70' : '?fit=clip&w=375&h=75&q=70';

	return (
		<>
			{banner && (
				<div className={classNames(css.banner, className)}>
					<a
						href={banner.bannerLink}
						target="_blank"
						rel="noreferrer"
						className={css.bannerLink}
						onClick={() => dataLayerEvents.onBannerClick(`banner num ${banner.orderData + 1}`)}
					>
						<img
							src={CAN_USE_DOM ? fileLinkIMGIX(banner.bannerImage, imageParams) : DEFAULT_IMAGE}
							alt="banner"
							className={css.bannerImage}
						/>
					</a>
				</div>
			)}
		</>
	);
};

Banner.defaultProps = {
	className: '',
};

export { Banner };
