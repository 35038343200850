// @flow
import * as React from 'react';
import classNames from 'classnames';

import { Text } from '../Text';
import { Icon } from '../Icon';
import * as css from './Button.scss';

type Props = {
	className?: string,
	label: string,
	bgColor?: string,
	labelColor?: string,
	disabled?: boolean,
	iconType?: string,
	isMobile?: boolean,
	iconPosition?: string,
};

class Button extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
		disabled: false,
		isMobile: false,
		bgColor: '#ff0000',
		labelColor: '#fff',
		iconType: '',
		iconPosition: 'left',
	};

	render() {
		const {
			className,
			label,
			bgColor,
			labelColor,
			disabled,
			isMobile,
			iconType,
			iconPosition,
			...rest
		} = this.props;

		return (
			<div
				{...rest}
				style={{
					backgroundColor: bgColor || Button.defaultProps.bgColor,
					color: labelColor || Button.defaultProps.labelColor,
				}}
				className={classNames(
					css.button,
					isMobile && css.isMobile,
					disabled && css.disabled,
					iconType && css.withIcon,
					css[iconPosition],
					className
				)}
			>
				<Text className={css.label} type="L1">
					{label}
				</Text>

				{iconType && <Icon type={iconType} className={classNames(css.icon)} />}
			</div>
		);
	}
}

export { Button };
