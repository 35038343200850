/* eslint-disable react/no-danger */
// @flow

import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import { CSSTransition } from 'react-transition-group';

// import { coverFileLink } from 'common/utils/cover-file-link';
import { Responsive } from 'client/components/common';
// import { fileLink } from 'common/utils';
import { COVER_PAGE_ROUTE } from 'client/constants';
import { CAN_USE_DOM, DEFAULT_IMAGE } from 'common/constants';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';
import heart from 'assets/client/Celebs/heart.png';
import CoverPopUp from '../../CoverPopUp';
import CelebsPopUp from './CelebsPopUp';
import CelebsNavigation from './CelebsNavigation';
import Description from './Description';
import css from './Celebs.scss';

type Props = {
	items: CelebItem[],
	covers: CoverItem[],
};

export default function Celebs(props: Props) {
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);

	const { items } = props;
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [activePopUp, setActivePopUp] = useState<boolean>(false);
	const [isLoadingImages, setIsLoadingImages] = useState({ one: true, two: true });
	const [isOpenCoverPopup, setIsOpenCoverPopup] = useState<boolean>(false);
	// const [popupInitialSlide, setPopupInitialSlide] = useState(null);
	// const [popupSwiper, setPopupSwiper] = useState(null);

	const [shuffledItems, setShuffledItems] = useState<CelebItem[]>([]);
	const [catalogLinkItem, setCatalogLinkItem] = useState<CoverItem | null>(null);
	const imageCoverParams = isDesktop ? '?fit=clip&w=280&h=380&q=70' : '?fit=clip&w=350&h=160&q=70';
	const imageCelebsParams = isDesktop ? '?fit=clip&w=295&h=530&q=70' : '?fit=clip&w=150&h=270&q=70';

	useEffect(() => {
		const arr = _.shuffle(items);
		setShuffledItems(arr);
	}, [items]);

	const setIndex = (index: number) => {
		setIsLoadingImages({ one: false, two: false });
		setActiveIndex(index);
	};

	const closePopUp = () => {
		setActivePopUp(false);
	};

	const closeCoverPopUp = () => {
		setIsOpenCoverPopup(false);
	};

	const onShowCoverPopUpClick = () => {
		setIsOpenCoverPopup(true);
	};

	useEffect(() => {
		if (!_.isEmpty(shuffledItems)) {
			const { coverId } = shuffledItems[activeIndex];
			const coverItem = _.find(props.covers, { id: coverId });
			if (coverItem) {
				setCatalogLinkItem(coverItem);
			}
		}
	}, [activeIndex, shuffledItems]);

	const renderImageSRC = i => {
		return !_.isEmpty(i.uploadedCover)
			? fileLinkIMGIX(i.uploadedCover, imageCoverParams)
			: `https://laishacovers.imgix.net/covers/LAI${i.fileName}.jpg${imageCoverParams}`;
	};

	return (
		!_.isEmpty(shuffledItems) && (
			<div className={css.celebs}>
				<div className={css.celebsInner}>
					{isOpenCoverPopup && catalogLinkItem && (
						<CoverPopUp item={catalogLinkItem} type="single" onCloseCoverPopUp={closeCoverPopUp} />
					)}
					<div className={css.bottomGradient}></div>
					<CSSTransition in={isLoadingImages.one && isLoadingImages.two} timeout={1300}>
						<div className={css.wrapper}>
							{shuffledItems[activeIndex].celebPhotographer && (
								<p className={css.photoCredit}>
									צילום:{' '}
									{shuffledItems[activeIndex].celebPhotographer
										? shuffledItems[activeIndex].celebPhotographer
										: ''}
								</p>
							)}
							{/* eslint-disable-next-line react/jsx-no-target-blank */}
							<a
								href={COVER_PAGE_ROUTE.replace(':id', catalogLinkItem ? catalogLinkItem?.id : '')}
								target="_blank"
								title={catalogLinkItem?.title || catalogLinkItem?.modelName}
								className={cn(css.coverImage, isLoadingImages.one && css.hidden, 'image')}
							>
								<img
									src={
										CAN_USE_DOM && catalogLinkItem ? renderImageSRC(catalogLinkItem) : DEFAULT_IMAGE
									}
									alt={catalogLinkItem?.title || catalogLinkItem?.modelName}
									onLoad={() => {
										setIsLoadingImages({ one: true, two: isLoadingImages.two });
									}}
								/>
							</a>
							<img
								src={
									CAN_USE_DOM
										? fileLinkIMGIX(shuffledItems[activeIndex].largeImg, imageCelebsParams)
										: DEFAULT_IMAGE
								}
								alt={shuffledItems[activeIndex].name}
								className={cn(css.celebImage, isLoadingImages.two && css.hidden, 'image')}
								onLoad={() => {
									setIsLoadingImages({ one: isLoadingImages.one, two: true });
								}}
							/>
							<div className={css.rightInnerWrapper}>
								<div id="celebs-top-content" className={css.celebsTopContent}>
									<h4 className={css.topTitle}>נשים משפיעות בוחרות</h4>
									<p className={css.name}>{shuffledItems[activeIndex].name}</p>
									<div
										dangerouslySetInnerHTML={{
											__html: shuffledItems[activeIndex].celebDescription,
										}}
										className={css.description}
									></div>
									<div className={css.titleWrapper}>
										<p className={css.name}>שער אהוב</p>
										<img src={heart} alt="Heart" className={css.heart} />
									</div>
								</div>
								<Description
									btnMoreLabel="קראו עוד"
									description={shuffledItems[activeIndex].content}
									className={css.description}
									activeIndex={activeIndex}
									setActivePopUp={() => setActivePopUp(true)}
									onShowCoverPopUpClick={onShowCoverPopUpClick}
								/>
							</div>
						</div>
					</CSSTransition>
					<CelebsNavigation items={shuffledItems} activeIndex={activeIndex} setIndex={setIndex} />
				</div>
				<CelebsPopUp
					activePopUp={activePopUp}
					name={shuffledItems[activeIndex].name}
					content={shuffledItems[activeIndex].content}
					celebDescription={shuffledItems[activeIndex].celebDescription}
					closePopUp={closePopUp}
					onShowCoverPopUpClick={onShowCoverPopUpClick}
					activeIndex={activeIndex}
				/>
			</div>
		)
	);
}
