// @flow
import { CAN_USE_DOM } from '../constants';

export const dataLayerEvents = {
	onShareCoverBtnClick: (label: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'Social',
			Action: 'Share From Cover',
			Label: label,
		}),
	onSubscribersBtnClick: () =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: 'Go To Subscribers',
		}),
	onLatishaHomeBtnClick: () =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: 'Go To Laisha_homepage',
		}),
	onSocialLinkClick: (label: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: label,
			Label: '',
		}),
	onHeaderNavigationBtnClick: (label: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: 'Navigation',
			Label: `Header-Clicked on ${label}`,
		}),
	onSearchTag: (label: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: 'Search a cover - by tag',
			Label: label,
		}),
	onSearchDate: (label: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: 'Search a cover - by date',
			Label: label,
		}),
	onSearchString: (label: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: 'Search a cover - by free search',
			Label: label,
		}),
	onSpecialArticleClick: (label: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: 'Special articles',
			Label: label,
		}),
	onCelebClick: (label: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: 'Celebs choose',
			Label: label,
		}),
	onBannerClick: (label: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: 'Banner Clicked',
			Label: label,
		}),
	onTimelineDotClick: (label: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: 'TimeLine',
			Label: label,
		}),
	onPageView: (label: string, text: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'virtualPageview',
			virtualPageURL: `/${label}`,
			virtualPageTitle: `${text}`,
		}),
	onVODClick: (label: string) =>
		CAN_USE_DOM &&
		window.dataLayer &&
		window.dataLayer.push({
			event: 'GA_events',
			Category: 'laisha 75th anniversary',
			Action: 'VOD',
			Label: label,
		}),
};
