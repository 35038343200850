// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import Timeline from './Timeline';

const mapState = (state: ClientStore) => {
	const timelineItems = _.filter(_.get(state, 'catalog.data', []), (i: CoverItem) => {
		return i.isShowingOnTimeline;
	});

	return {
		items: timelineItems,
	};
};

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Timeline);
