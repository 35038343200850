// @flow
import { combineReducers } from 'redux';
import type { Reducer, Action } from 'redux';
import { reducer as formReducer } from 'redux-form';

import catalog from './catalog';
import articles from './articles';
import videos from './videos';
import celebs from './celebs';
import settings from './settings';
import banners from './banners';
import currentCover from './current-cover';

const reducer: Reducer<ClientStore, Action<{ type: string, payload: any }>> = combineReducers({
	catalog,
	articles,
	videos,
	celebs,
	settings,
	banners,
	currentCover,
	form: formReducer,
});

export default reducer;
