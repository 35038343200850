/* eslint-disable react/no-danger */
// @flow

import React, { useEffect } from 'react';
import cn from 'classnames';

import heart from 'assets/client/Celebs/heart.png';
import closeImg from 'assets/client/PopUp/close.png';
import css from './CelebsPopUp.scss';

type Props = {
	activePopUp: boolean,
	closePopUp: () => void,
	name: string,
	content: string,
	celebDescription: string,
	onShowCoverPopUpClick: any => void,
	activeIndex: number,
};
export default function CelebsPopUp(props: Props) {
	const { activePopUp, closePopUp, name, content, celebDescription, onShowCoverPopUpClick, activeIndex } = props;

	useEffect(() => {
		const descriptionTag = document.getElementById('descriptionPopUp');
		if (descriptionTag) {
			const descriptionTextP = descriptionTag.getElementsByTagName('p')[0];
			const descriptionLink = descriptionTextP.getElementsByTagName('a')[0];
			if (descriptionLink) {
				descriptionLink.addEventListener('click', (e: any) => onShowCoverPopUpClick(e));
			}
		}
	}, [activeIndex]);

	return (
		<div className={cn(css.popUp, activePopUp && css.visible)}>
			<div className={css.overlay} onClick={closePopUp} />
			<div className={css.popUpInner}>
				<div className={css.innerWindow}>
					<div className={css.closeButtonWrapper}>
						<img src={closeImg} className={css.closeButton} alt="Close" onClick={closePopUp} />
					</div>
					<div className={css.contentWrapper}>
						<div className={css.innerContentWrapper}>
							<h4 className={css.topTitle}>נשים משפיעות בוחרות </h4>
							<p className={css.title}>{name}</p>
							<p
								className={css.celebDescription}
								dangerouslySetInnerHTML={{ __html: celebDescription }}
							></p>
							<div className={css.titleWrapper}>
								<p className={css.name}>שער אהוב</p>
								<img src={heart} alt="Heart" className={css.heart} />
							</div>
							<p
								id="descriptionPopUp"
								dangerouslySetInnerHTML={{ __html: content }}
								className={css.content}
							></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
