// @flow
import * as React from 'react';
import classNames from 'classnames';

import poweredByYIT from 'assets/client/poweredByYIT.png';
import * as css from './Footer.scss';

type Props = {
	className?: string,
};

const Footer = ({ className }: Props) => {
	return (
		<footer className={classNames(css.footer, className)}>
			<div className={css.footerInner}>
				<a href="https://yit.co.il/" target="_blank" rel="noreferrer">
					<img src={poweredByYIT} className={css.powered} alt="Powered by YIT" />
				</a>
				<div className={css.linkWrapper}>
					<a
						href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html"
						className={css.link}
						target="_blank"
						rel="noreferrer"
					>
						תנאי שימוש
					</a>
					<p>|</p>
					<a
						href="https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html"
						className={css.link}
						target="_blank"
						rel="noreferrer"
					>
						מדיניות פרטיות
					</a>
				</div>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	className: '',
};

export { Footer };
