// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import { CAN_USE_DOM } from 'common/constants';
import { Header, Footer, FooterMobile, Banner } from 'client/components/common';
import ArticlesSection from 'client/components/common/Sections/Articles';
import VideoSection from 'client/components/common/Sections/VOD';
import Leads from 'client/components/common/Sections/Leads';
import Catalog from 'client/components/common/Sections/Catalog';
import Timeline from 'client/components/common/Sections/Timeline';
import Celebs from 'client/components/common/Sections/Celebs';
import Gallery from 'client/components/common/Sections/Gallery';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { Responsive, isMobileOrTablet } from 'client/components/common/Responsive';

import { ANCORS } from 'client/constants';

import * as css from './Main.scss';
// import _ from 'lodash';

type Props = {|
	...EnhancedProps,
	in: boolean,
	banners: BannersItem[],
|};

type State = {
	isFixedHeader: boolean,
	headerH: number,
};

class Main extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	isDesktop = !isMobileOrTablet();

	state = {
		isFixedHeader: false,
		headerH: 0,
	};

	componentDidMount() {
		const { onTransitionComplete } = this.props;
		this.transitionCompleteTimeout = setTimeout(onTransitionComplete, parseInt(css.transitionIn, 10));
		const body: HTMLElement | null = document.querySelector('body');
		if (body) {
			// !todo: fix flow type
			body.addEventListener('scroll', this.onScroll);
		}
		const headerH = document.getElementById('header')?.offsetHeight;

		this.setState({ headerH });
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	renderHelmet = () => {
		const title = 'ארכיון שערי לאשה';
		// eslint-disable-next-line max-len
		const description = `מגזין לאשה מציג ארכיון ייחודי של כל שעריו משנת 1947 עד היום. בואו להיזכר ברגעים הכי גדולים ולסייר בין דמויות בלתי נשכחות`;
		// const shareTitle = '';
		// const shareDescription = 'share description';
		const canonicalURl = 'https://laishacovers.ynet.co.il';
		const shareImageURL = `${canonicalURl}/assets/client/share_1.jpg`;

		return (
			<Helmet
				title={title}
				link={[
					{ rel: 'icon', type: 'image/png', href: 'assets/favicon.png' },
					{ rel: 'canonical', href: canonicalURl },
				]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: description },
					{ property: 'og:image', content: shareImageURL },
				]}
			/>
		);
	};

	onScroll = (e: { currentTarget: HTMLElement }) => {
		if (Responsive.isMatching(Responsive.DESKTOP) && CAN_USE_DOM) {
			if (e.currentTarget.scrollTop > this.state.headerH) {
				this.setState({ isFixedHeader: true });
			} else {
				this.setState({ isFixedHeader: false });
			}
		}
	};

	render() {
		const { transitionState, banners } = this.props;
		const { isFixedHeader } = this.state;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		return (
			<div className={classNames(css.main, css[transitionState])}>
				{this.renderHelmet()}
				<Header isFixed={isFixedHeader} />
				<div className={css.mainContent}>
					<div className={classNames(css.container, css.gallery)}>
						<Gallery />
					</div>
					<div id={ANCORS.CATALOG} className={css.catalog}>
						<Catalog />
					</div>
					<Banner banner={_.get(banners, '[0]')} />
					<div id={ANCORS.ARTICLES} className={css.articles}>
						<ArticlesSection />
					</div>
					<div id={ANCORS.TIMELINE} className={css.timeline}>
						<Timeline />
					</div>
					<Banner banner={_.get(banners, '[1]')} />
					<div id={ANCORS.VIDEOS} className={css.videos}>
						<VideoSection />
					</div>
					<div id={ANCORS.CELEBS} className={css.celebs}>
						<Celebs />
					</div>
					<Banner banner={_.get(banners, '[2]')} />
					<div id={ANCORS.LEADS} className={css.leads}>
						<Leads />
					</div>
				</div>
				{!isMobile ? <Footer /> : <FooterMobile />}
			</div>
		);
	}
}

export default withTransition(Main);
