// @flow
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon, Button, Responsive } from 'client/components/common';

import * as css from './DatePickerPopup.scss';

type Props = {
	className?: string,
	setSelectedDate: Function,
	onClosePopup: Function,
	initialData: {
		months: string[],
		year: string,
	},
};

type State = {
	isSelectOpened: boolean,
	selectedYear: string,
	selectedMonths: string[],
	notification: string,
};

export const MONTHS = [
	{
		monthNumber: '01',
		monthName: 'ינואר',
	},
	{
		monthNumber: '02',
		monthName: 'פברואר',
	},
	{
		monthNumber: '03',
		monthName: 'מרץ',
	},
	{
		monthNumber: '04',
		monthName: 'אפריל',
	},
	{
		monthNumber: '05',
		monthName: 'מאי',
	},
	{
		monthNumber: '06',
		monthName: 'יוני',
	},
	{
		monthNumber: '07',
		monthName: 'יולי',
	},
	{
		monthNumber: '08',
		monthName: 'אוגוסט',
	},
	{
		monthNumber: '09',
		monthName: 'ספטמבר',
	},
	{
		monthNumber: '10',
		monthName: 'אוקטובר',
	},
	{
		monthNumber: '11',
		monthName: 'נובמבר',
	},
	{
		monthNumber: '12',
		monthName: 'דצמבר',
	},
];

const CHOOSE_YEAR = 'אנא בחרו שנה';
const DEFAULT_MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

class DatePickerPopup extends React.PureComponent<Props, State> {
	popupRef: { current: ?HTMLElement } | null;
	buttonRef: { current: ?HTMLElement } | null;

	static defaultProps = {
		className: '',
	};

	state = {
		isSelectOpened: false,
		selectedYear: '',
		selectedMonths: [],
		notification: '',
	};

	constructor() {
		super();

		this.popupRef = React.createRef();
		this.buttonRef = React.createRef();
	}

	componentDidMount() {
		const { months, year } = this.props.initialData;

		this.setState({ selectedYear: year, selectedMonths: months });
	}

	getYears = (a: number, b: number) => {
		const yearsArr: string[] = [];

		for (let i = 0; i <= b - a; i += 1) {
			const year = (a + i).toString();

			yearsArr.push(year);
		}
		yearsArr.reverse();

		return yearsArr;
	};

	onYearHolderClick = () => {
		this.setState(prev => {
			return { isSelectOpened: !prev.isSelectOpened };
		});
	};

	onYearsListItemClick = (e: SyntheticEvent<HTMLDivElement>) => {
		const { year } = e.currentTarget.dataset;

		this.setState({ selectedYear: year, isSelectOpened: false, notification: '' });
	};

	onMonthsListItemClick = (e: SyntheticEvent<HTMLDivElement>) => {
		const { month } = e.currentTarget.dataset;

		if (_.includes(this.state.selectedMonths, month)) {
			this.setState(prev => {
				const selected = prev.selectedMonths.filter(i => i !== month);
				return { selectedMonths: selected };
			});
		} else {
			this.setState(prev => {
				return { selectedMonths: [...prev.selectedMonths, month] };
			});
		}
	};

	onButtonClick = () => {
		if (!this.state.selectedYear) {
			this.setState({ notification: CHOOSE_YEAR });
		} else {
			this.props.setSelectedDate({
				months: _.isEmpty(this.state.selectedMonths) ? DEFAULT_MONTHS : this.state.selectedMonths,
				year: this.state.selectedYear,
			});
			this.props.onClosePopup();
		}
	};

	onOverlayClick = () => {
		this.props.onClosePopup();
	};

	onCloseClick = () => {
		this.props.onClosePopup();
	};

	render() {
		const { className } = this.props;
		const { isSelectOpened, selectedYear, selectedMonths, notification } = this.state;
		const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
		const popupTitle = 'לאיזו שנה לקחת אותך?';
		const yearValue = selectedYear || 'בחרו מהרשימה';
		const monthTitle = 'בחרו חודש';
		const buttonLabelDesktop = 'קחו אותי לשם!';
		const buttonLabelMobile = 'קחו אותי לשם';
		const buttonLabel = isDesktop ? buttonLabelDesktop : buttonLabelMobile;

		return (
			<div className={classNames(css.datepickerPopup, className)}>
				<div className={css.datepickerPopupContent}>
					<Icon type="close-icon" className={classNames(css.closeIcon)} onClick={this.onCloseClick} />
					<h6 className={css.datepickerTitle}>{popupTitle}</h6>
					<div className={css.selectYear}>
						<div
							className={classNames(css.selectYearHolder, selectedYear && css.selected)}
							onClick={this.onYearHolderClick}
						>
							<Icon
								type="arrow-down"
								className={classNames(css.arrowIcon, isSelectOpened && css.arrowTop)}
							/>
							<span className={css.selectedText}>{yearValue}</span>
						</div>
						{notification && <div className={css.notification}>{notification}</div>}
						<div className={classNames(css.yearsList, isSelectOpened && css.openedSelect)}>
							<div className={css.yearsListInner}>
								{_.map(this.getYears(1947, new Date().getFullYear()), y => (
									<div
										className={classNames(
											css.yearsListItem,
											selectedYear === y && css.selectedYear
										)}
										key={y}
										data-year={y}
										onClick={this.onYearsListItemClick}
									>
										{y}
									</div>
								))}
							</div>
						</div>
					</div>
					<h6 className={css.datepickerTitle}>{monthTitle}</h6>
					<div className={css.monthsList}>
						{_.map(MONTHS, m => (
							<div
								key={m.monthNumber}
								data-month={m.monthNumber}
								className={classNames(
									css.monthsListItem,
									_.includes(selectedMonths, m.monthNumber) && css.selectedMonth
								)}
								onClick={this.onMonthsListItemClick}
							>
								<span className={css.monthName}>{m.monthName}</span>
								{isDesktop ? (
									<>
										<Icon type="month" className={css.iconMonth} />
										<Icon type="month-selected" className={css.iconMonthSelected} />
									</>
								) : (
									<div className={css.iconMonthMobile}>
										<Icon type="month-mobile" className={css.iconMonth} />
										<Icon type="month-selected-mobile" className={css.iconMonthSelected} />
									</div>
								)}
							</div>
						))}
					</div>

					<Button label={buttonLabel} onClick={this.onButtonClick} className={css.button} />
				</div>
				<div className={css.overlay} onClick={this.onOverlayClick}></div>
			</div>
		);
	}
}

export default DatePickerPopup;
