// @flow
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon, Button, Responsive } from 'client/components/common';

import * as css from './TagsPopup.scss';

type Props = {
	className?: string,
	onClosePopup: Function,
	selectedTag: string,
	tags: string[],
	setSelectedTag: Function,
};

type State = {
	tagsList: string[],
	activeTag: string,
};

class TagsPopup extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			tagsList: [],
			activeTag: props.selectedTag,
		};
	}

	componentDidMount() {
		const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
		const smallTags = _.filter(this.props.tags, t => t.length < 13 && t.length > 0);
		const largeTags = _.filter(this.props.tags, t => t.length >= 13);
		const chunkedSmall = _.chunk(smallTags, isDesktop ? 3 : 2);
		const chunkedLarge = _.chunk(largeTags, isDesktop ? 2 : 1);

		const combinedArray = [];
		_.map(chunkedSmall, (item, index) => {
			combinedArray.push(...item);

			if (chunkedLarge[index]) {
				combinedArray.push(...chunkedLarge[index]);
			}
		});
		this.setState({ tagsList: combinedArray });
	}

	onButtonClick = () => {
		this.props.setSelectedTag(this.state.activeTag);
		this.props.onClosePopup();
	};

	onTagClick = (e: SyntheticEvent<HTMLButtonElement>) => {
		const { tag } = e.currentTarget.dataset;

		if (this.state.activeTag === tag) {
			this.setState({ activeTag: '' });
		} else {
			this.setState({ activeTag: tag });
		}
	};

	onOverlayClick = () => {
		this.props.onClosePopup();
	};

	onCloseClick = () => {
		this.props.onClosePopup();
	};

	render() {
		const { className } = this.props;
		const { tagsList, activeTag } = this.state;
		const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
		const popupTitle = 'בחרי בתגיות הרצויות';
		const buttonLabelDesktop = 'קחו אותי לשם!';
		const buttonLabelMobile = 'קחו אותי לשם';
		const buttonLabel = isDesktop ? buttonLabelDesktop : buttonLabelMobile;

		return (
			<div className={classNames(css.tagsPopup, className)}>
				<div className={css.tagsPopupContent}>
					<Icon type="close-icon" className={classNames(css.closeIcon)} onClick={this.onCloseClick} />
					<h6 className={css.tagsTitle}>{popupTitle}</h6>

					<div className={css.tagsList}>
						<div className={css.tagsListInner}>
							{_.map(tagsList, (t, index) => {
								/* const tag = t.slice(0, 11); */

								return (
									<div
										key={t}
										className={classNames(
											css.tagsListItem,
											activeTag === t && css.tagsListItemSelected
										)}
										data-tag={t}
										onClick={this.onTagClick}
									>
										{t}
										{/* {t.len gth > 11 ? `${tag}...` : t} */}
									</div>
								);
							})}
						</div>
					</div>

					<Button label={buttonLabel} onClick={this.onButtonClick} className={css.button} />
				</div>
				<div className={css.overlay} onClick={this.onOverlayClick}></div>
			</div>
		);
	}
}

export default TagsPopup;
