// @flow
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { CAN_USE_DOM, DEFAULT_IMAGE } from 'common/constants';
import classNames from 'classnames';
// import { coverFileLink } from 'common/utils/cover-file-link';
// import { fileLink } from 'common/utils/file-link';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';

import { Icon } from '../Icon';
import { Responsive } from '../Responsive';
import Socialls from '../Socialls';

import * as css from './CoverPopUp.scss';

type Props = {|
	className?: string,
	item: CoverItem,
	type: 'single' | 'slide',
	slideType?: 'fullHeight' | '',
	onCloseCoverPopUp?: Function,
	isScrolling?: boolean,
|};

const CoverPopUp = ({ item, className, type, onCloseCoverPopUp, isScrolling, slideType }: Props) => {
	const [isScrolledContent, setIsScrolledContent] = useState(false);
	const [touchStartPoint, setTouchStartPoint] = useState(0);

	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const creditTitle = 'צילום';
	const imageParams = isDesktop ? '?fit=clip&w=580&h=890&q=85000&q=70' : '?fit=clip&w=275&h=400&q=8500&q=70';

	useEffect(() => {
		if (CAN_USE_DOM && document.body && type !== 'slide') {
			document.body.style.overflow = 'hidden';
		}
		return () => {
			if (CAN_USE_DOM && document.body) {
				document.body.style.overflow = '';
			}
		};
	}, []);

	const renderImageSRC = i => {
		return !_.isEmpty(i.uploadedCover)
			? fileLinkIMGIX(i.uploadedCover, imageParams)
			: `https://laishacovers.imgix.net/covers/LAI${i.fileName}.jpg${imageParams}`;
	};

	const renderItem = () => {
		return (
			<div className={classNames(css.itemContainer, css[type])}>
				<div
					className={classNames(
						css.coverPopupContent,
						(isScrolling || isScrolledContent) && css.isScrolling,
						css[slideType]
					)}
				>
					<div className={css.content}>
						{!isDesktop && <Socialls className={css.socials} options={{ url: `${item.id}/` }} />}
						<div>
							<div className={css.topTextWrapper}>
								<div className={css.title}>{item.modelName}</div>
								<div className={css.date}>{item.date}</div>
							</div>
							<div className={css.textWrapper}>
								<div className={css.text} dangerouslySetInnerHTML={{ __html: item.content }} />
							</div>
						</div>

						{isDesktop && <Socialls className={css.socials} options={{ url: `${item.id}/` }} />}
					</div>
					<div className={css.coverWrap}>
						<div className={css.coverImage}>
							{type === 'slide' ? (
								<img
									data-src={CAN_USE_DOM ? renderImageSRC(item) : DEFAULT_IMAGE}
									alt={item.title}
									className="swiper-lazy"
								/>
							) : (
								<img src={CAN_USE_DOM ? renderImageSRC(item) : DEFAULT_IMAGE} alt={item.title} />
							)}
						</div>
						<div className={css.credits}>
							{creditTitle}
							{' : '}
							{`${item.photoCredit}`}
						</div>
					</div>
				</div>
			</div>
		);
	};

	const handleTouchStart = e => {
		setTouchStartPoint(_.get(e, 'changedTouches[0].clientY', 0));
	};

	const handleTouchEnd = e => {
		const endPoint = _.get(e, 'changedTouches[0].clientY', 0);
		handleTouchMove(endPoint, touchStartPoint);
	};

	const handleTouchMove = (endPoint, startPoint) => {
		if (endPoint < startPoint) {
			setIsScrolledContent(true);
		} else {
			setIsScrolledContent(false);
		}
	};

	const onPopupCloseClick = () => {
		if (onCloseCoverPopUp) {
			onCloseCoverPopUp();
		}
	};

	return (
		<>
			{type === 'single' ? (
				<div
					className={classNames(css.coverPopup, isScrolledContent && css.isScrolling)}
					onTouchStart={!isDesktop ? handleTouchStart : null}
					onTouchEnd={!isDesktop ? handleTouchEnd : null}
				>
					<Icon type="close-icon" onClick={onPopupCloseClick} className={css.closeIcon} />
					{renderItem()}
				</div>
			) : (
				renderItem()
			)}
		</>
	);
};

CoverPopUp.defaultProps = {
	className: '',
	onCloseCoverPopUp: _.noop,
	isScrolling: false,
	slideType: '',
};

export default CoverPopUp;
