// @flow
/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
// import _ from 'lodash';
import { CAN_USE_DOM } from 'common/constants';
import classNames from 'classnames';

import { trimHtml } from 'client/utils/trim-html';
// import { Responsive } from '../../../Responsive';

import * as css from './Description.scss';

type Props = {|
	className?: string,
	description: string,
	btnMoreLabel: string,
	activeIndex: number,
	setActivePopUp: Function,
	onShowCoverPopUpClick: Function,
|};

const Description = ({
	description,
	btnMoreLabel,
	activeIndex,
	setActivePopUp,
	onShowCoverPopUpClick,
	className,
}: Props) => {
	const descriptionBlockRef = useRef<HTMLParagraphElement | null>(null);
	const [visibleDescription, setVisibleDescription] = useState<string>('');
	// const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	let popUpLink = null;
	let descriptionLink = null;
	let timeout = null;

	useEffect(() => {
		return () => {
			if (popUpLink) {
				popUpLink.removeEventListener('click', onShowMoreClick);
			}
			if (descriptionLink) {
				descriptionLink.removeEventListener('click', onPinkLinkClick);
			}
			clearTimeout(timeout);
		};
	}, []);

	useEffect(() => {
		if (description && CAN_USE_DOM) {
			const options = { limit: 280, suffix: '... <b id="popUp">קראו עוד</b>' };
			const cuteedDescription = trimHtml(description, options);
			setVisibleDescription(cuteedDescription.html);
		}

		timeout = setTimeout(() => {
			//
			// Add click handler to 'show more': should open CelebsPopUp
			//
			popUpLink = document.getElementById('popUp');
			if (popUpLink) {
				popUpLink.addEventListener('click', onShowMoreClick);
			}

			//
			// Add click handler to pink link: should open CoverPopUp
			//
			const descriptionTag = document.getElementById('description');
			if (descriptionTag) {
				const descriptionTagP = descriptionTag.getElementsByTagName('p')[0];
				// eslint-disable-next-line prefer-destructuring
				descriptionLink = descriptionTagP.getElementsByTagName('a')[0];
				if (descriptionLink) {
					descriptionLink.addEventListener('click', onPinkLinkClick);
				}
			}
		}, 1500);
	}, [description]);

	const onPinkLinkClick = (e: MouseEvent) => {
		e.preventDefault();
		onShowCoverPopUpClick(e);
	};

	const onShowMoreClick = () => {
		setActivePopUp(true);
	};

	return (
		<div className={classNames(css.description, className)} id="description">
			<div className={css.description}>
				<p ref={descriptionBlockRef} dangerouslySetInnerHTML={{ __html: visibleDescription }} />
			</div>
		</div>
	);
};

Description.defaultProps = {
	className: '',
};

export default Description;
