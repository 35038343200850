// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import Catalog from './Catalog';

const mapState = (state: ClientStore) => ({
	items: _.get(state, 'catalog.data', []),
	settings: _.get(state, 'settings.data', []),
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Catalog);
