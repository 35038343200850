// @flow

import React from 'react';
// import { fileLink } from 'common/utils';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';
import { CAN_USE_DOM, IS_DEVEL, DEFAULT_IMAGE } from 'common/constants';
import { dataLayerEvents } from 'common/utils/gtag';
import { Responsive } from 'client/components/common';
import css from './Articles.scss';

type Props = {|
	item: typeof ArticleItem,
|};
export default function ArticleItem(props: Props) {
	const { item } = props;
	const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
	const imageParams = isDesktop ? '?fit=clip&w=400&h=230&q=70' : '?fit=clip&w=260&h=150&q=70';

	const clickOnArticle = () => {
		if (!IS_DEVEL) {
			dataLayerEvents.onSpecialArticleClick(item.title);
		}
	};

	return (
		<a href={item.url} onClick={clickOnArticle} target="_blank" className={css.item} rel="noreferrer">
			<img
				src={CAN_USE_DOM ? fileLinkIMGIX(item.img, imageParams) : DEFAULT_IMAGE}
				alt={item.title}
				className={css.img}
			/>
			<div className={css.bottom}>
				<div>
					<p className={css.categoryName}>{item.category}</p>
					<h5 className={css.title}>{item.title}</h5>
				</div>
				<div className={css.itemFooter}>
					<p>
						{item.reporterName} | {item.date}
					</p>
				</div>
			</div>
		</a>
	);
}
