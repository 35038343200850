// @flow
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Lazy } from 'swiper';
import _ from 'lodash';
import classNames from 'classnames';

// import { coverFileLink } from 'common/utils/cover-file-link';
// import { fileLink } from 'common/utils/file-link';
import { COVER_PAGE_ROUTE } from 'client/constants';
import { CAN_USE_DOM, DEFAULT_IMAGE } from 'common/constants';
import { fileLinkIMGIX } from 'common/utils/file-link-imgix';
import css from './TimelineMobile.scss';

SwiperCore.use([Lazy]);

type Props = {
	items: Array<CoverItem>,
	setActiveIndex: any,
	activeIndex: number,
};
export default function TimelineMobile(props: Props) {
	const { items, setActiveIndex, activeIndex } = props;
	const [swiper, setSwiper] = useState<Swiper>();
	const imageParams = '?fit=clip&w=220&h=295&q=70';

	const onSlideChange = (e: any) => {
		if (e.realIndex !== activeIndex) {
			setActiveIndex(+e.realIndex);
		}
	};

	const onClickSlide = (e: any) => {
		setActiveIndex(e);
	};

	const swipeTo = _.throttle(() => {
		swiper.slideTo(activeIndex);
	}, 400);

	useEffect(() => {
		if (swiper && swiper.realIndex !== activeIndex) {
			swipeTo();
		}
	}, [activeIndex, swiper]);

	const renderImageSRC = i => {
		return !_.isEmpty(i.uploadedCover)
			? fileLinkIMGIX(i.uploadedCover, imageParams)
			: `https://laishacovers.imgix.net/covers/LAI${i.fileName}.jpg${imageParams}`;
	};

	return (
		<Swiper
			className={css.swiper}
			slidesPerView={2}
			centeredSlides
			spaceBetween={90}
			initialSlide={activeIndex}
			lazy={{ loadPrevNext: true }}
			onRealIndexChange={onSlideChange}
			onSwiper={sw => setSwiper(sw)}
		>
			{_.map(items, (item, index) => {
				return (
					<SwiperSlide className={css.swiperSlide} key={item.id} onClick={e => onClickSlide(index)}>
						{/* eslint-disable-next-line react/jsx-no-target-blank */}
						<a
							href={COVER_PAGE_ROUTE.replace(':id', item.id)}
							target="_blank"
							title={item.title || item.modelName}
						>
							<img
								data-src={CAN_USE_DOM ? renderImageSRC(item) : DEFAULT_IMAGE}
								alt={item.title || item.modelName}
								className={classNames(css.coverImage, 'swiper-lazy')}
							/>
						</a>
					</SwiperSlide>
				);
			})}
		</Swiper>
	);
}
