// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import Gallery from './Gallery';

const mapState = (state: ClientStore) => {
	const filtered = _.filter(_.get(state, 'catalog.data', []), (i: CoverItem) => {
		return i.isShowingOnGallery;
	});

	const items = filtered.slice(0, 30);

	return {
		items,
	};
};

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Gallery);
