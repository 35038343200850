// @flow
import produce from 'immer';
import _ from 'lodash';
import { COLLECTIONS } from 'client/constants';
import * as actions from 'client/constants/actions';

type Action = {
	type: string,
	payload: any,
};

type Videos = {
	data: {
		list: Video[],
		total: number,
		collection: string,
	},
	isBusy: false,
};

const initialState: Videos = {
	isBusy: false,
	data: { total: 0, list: [], collection: '' },
};

export default function articlesReducer(state: Videos = initialState, { type, payload }: Action) {
	return produce(state, draft => {
		switch (type) {
			case actions.GET_ITEMS.START:
				if (payload.collection === COLLECTIONS.VIDEO) {
					_.set(draft, 'isBusy', true);
				}
				break;

			case actions.GET_ITEMS.SUCCESS:
				if (payload.collection === COLLECTIONS.VIDEO) {
					_.set(draft, 'data', payload);
					_.set(draft, 'isBusy', false);
				}
				break;

			case actions.GET_ITEMS.FAIL:
				if (payload.collection === COLLECTIONS.VIDEO) {
					_.set(draft, 'isBusy', false);
				}
				break;

			default:
				break;
		}
	});
}
