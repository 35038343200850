// @flow

import { CAN_USE_DOM } from 'common/constants';
import _ from 'lodash';
import { SHARE_CONSTANTS } from 'client/constants/common';
import { Responsive } from 'client/components/common/Responsive';

export const share = (type: string, options?: { url?: string }, cb: Function = _.noop) => {
	if (!CAN_USE_DOM) {
		return;
	}

	// const domain = CAN_USE_DOM ? `${window.location.href}` : '';
	const domain = CAN_USE_DOM ? `${window.location.origin}` : '';
	const titleProp = CAN_USE_DOM ? document.querySelector('meta[property="og:title"]') : null;
	const title: ?string = titleProp ? titleProp.getAttribute('content') : '';
	const descriptionProp = CAN_USE_DOM ? document.querySelector('meta[property="og:description"]') : null;
	const description: ?string = descriptionProp ? descriptionProp.getAttribute('content') : '';
	// const imageProp = CAN_USE_DOM ? document.querySelector('meta[property="og:image"]') : null;
	// const image: ?string = imageProp ? imageProp.getAttribute('content') : '';
	const encode = encodeURIComponent;
	const mail = '';
	const text = title && description ? `${title}\n${description}` : '';
	let url = domain;

	if (options?.url) {
		url = `${domain}/${options?.url}`;
	}
	if (type === SHARE_CONSTANTS.FACEBOOK) {
		const fbURL = 'https://www.facebook.com/sharer.php?u=';

		window.open(`${fbURL}${encode(url)}`, 'sharer', 'width=555,height=600');
	}

	if (type === SHARE_CONSTANTS.WHATSAPP) {
		const ecodedURL = url.split(' ').join('+');
		if (title && description) {
			if (Responsive.isMatching(Responsive.DESKTOP)) {
				window.open(
					`https://web.whatsapp.com/send?text=${encode(`${title}\n${description}\n${ecodedURL}`)}`,
					'_blank'
				);
			} else {
				window.location.href = `whatsapp://send?text=${encode(`${title}\n${description}\n${url}`)}`;
			}
		}
	}

	if (type === SHARE_CONSTANTS.EMAIL) {
		if (title && description && domain) {
			const ecodedURL = url.split(' ').join('+');
			window.location.href = `mailto:${mail}?subject=${encode(title)}&body=${encode(description)}${encode(
				`\n\n`
			)}${encode(ecodedURL)}`;
		}
	}

	if (type === SHARE_CONSTANTS.TWITTER) {
		const twURL = 'https://twitter.com/intent/tweet?url=';
		const finalURL = `${twURL}${url}&text=${encode(`${text}\n`)}`;

		const shareWindow = window.open(finalURL, 'ShareOnTwitter', 'width=555,height=600');
		shareWindow.opener = null;
	}

	// cb();
};
