/* eslint-disable react/no-danger */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import * as css from './TextField.scss';

// const onlyHebrewPattern = new RegExp(/^[\u0590-\u05FF 0-9 /\W/g\r\n-]+$/);
// const onlyHebrewPattern2 = new RegExp(/^[\u0590-\u05FF r\n-]+$/);
// const onlyHebrewPatternAdressField = new RegExp(/^[\u0590-\u05FF 0-9 r\n-]+$/);

type Props = {
	input: {|
		name: string,
		onBlur: Function,
		onChange: Function,
		onDragStart: Function,
		onDrop: Function,
		onFocus: Function,
		value?: string | number,
	|},
	meta: {|
		active?: boolean,
		asyncValidating?: boolean,
		autofilled?: boolean,
		dirty?: boolean,
		dispatch?: Function,
		error?: string,
		form?: string,
		initial?: string,
		invalid?: boolean,
		pristine?: boolean,
		submitFailed?: boolean,
		submitting?: boolean,
		touched?: boolean,
		valid?: boolean,
		visited?: boolean,
		warning?: string,
	|},
	inputRef?: { current: ?HTMLInputElement | ?HTMLTextAreaElement } | null,
	className?: string,
	placeholder?: string,
	eventListeners: {|
		onChange?: Function,
		onFocus?: Function,
		onBlur?: Function,
		onKeyDown?: Function,
		onKeyUp?: Function,
	|},
	isTextarea?: boolean,
	textareaType?: number,
	inlineType?: boolean,
	disabled?: boolean,
	clearValue?: boolean,
	maxWordsLength?: number,
	isNumberField?: boolean,
	minNumber?: number,
	maxNumber?: number,
	type?: string,
	pattern?: string,
};

type State = {|
	isFocused: boolean,
	value?: string | number,
	valueState?: number | string,
|};

export default class Input extends React.PureComponent<Props, State> {
	static defaultProps = {
		inputRef: null,
		className: '',
		placeholder: '',
		isTextarea: false,
		textareaType: 1,
		inlineType: false,
		disabled: false,
		clearValue: false,
		maxWordsLength: 1500,
		isNumberField: false,
		minNumber: 0,
		maxNumber: 120,
		type: '',
		pattern: '',
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			isFocused: false,
			valueState: '',
		};
	}

	onFocus = (e: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({ isFocused: true });

		_.invoke(this.props, 'input.onFocus', e);
	};

	onBlur = (e: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({ isFocused: false });

		_.invoke(this.props, 'input.onBlur', e);
	};

	onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const { valueState } = this.state;

		this.setState({ valueState: value });

		if (valueState?.length === 0) {
			_.invoke(this.props, 'input.onChange', e);
		}
	};

	render() {
		const {
			className,
			placeholder,
			isTextarea,
			inlineType,
			// disabled,
			clearValue,
			inputRef,
			type,
			pattern,
			...rest
		} = this.props;
		const { isFocused } = this.state;

		const value = clearValue ? '' : _.get(rest, 'input.value', '');
		const isErrorShown = (rest.meta.error && rest.meta.submitFailed) || (rest.meta.error && rest.meta.touched);
		// const value = _.get(rest, 'input.value', '');

		return (
			<>
				<label
					className={classNames(
						css.textField,
						(isFocused || value) && css.active,
						isFocused && css.focused,
						className
					)}
				>
					{placeholder && (
						<div
							className={classNames(css.placeholder, value && css.hide, isTextarea && css.forTextarea)}
							dangerouslySetInnerHTML={{ __html: placeholder }}
						></div>
					)}
					<div className={classNames(css.inputWrapper, this.state.isFocused ? css.active : '')}>
						<input
							{...rest.input}
							value={this.state.valueState}
							ref={inputRef}
							{...rest.eventListeners}
							onFocus={this.onFocus}
							onBlur={this.onBlur}
							className={classNames(inlineType && css.inline, isErrorShown && css.error)}
							onChange={this.onChange}
							type={type || 'text'}
							onKeyPress={e => {
								if (pattern) {
									const regExp = new RegExp(pattern);
									// console.info(regExp, regExp.test(e.key));
									if (!regExp.test(e.key)) {
										e.preventDefault();
									}
								}
							}}
						/>
					</div>
					<div className={classNames('error', css.error)}>{isErrorShown && <div>{rest.meta.error}</div>}</div>
				</label>
			</>
		);
	}
}
