// @flow

import produce from 'immer';
import _ from 'lodash';

import * as actions from 'client/constants/actions';

export type Action = {
	type: string,
	payload: any,
};

const initialState: ClientBanners = {
	isBusy: false,
	data: [],
};

export default function bannersReducer(state: ClientBanners = initialState, { type, payload }: Action) {
	return produce(state, draft => {
		switch (type) {
			case actions.GET_BANNERS.START:
				_.set(draft, 'isBusy', true);
				break;

			case actions.GET_BANNERS.SUCCESS:
				_.set(draft, 'data', payload.items);
				_.set(draft, 'isBusy', false);
				break;

			case actions.GET_BANNERS.FAIL:
				_.set(draft, 'isBusy', false);
				break;

			default:
				break;
		}
	});
}
