// @flow
import React from 'react';
import css from './TimelineMobile.scss';

type Props = {
	setActiveIndex: any,
	maxValue: number,
	activeIndex: number,
};
export default function SliderNavigation(props: Props) {
	const { setActiveIndex, maxValue, activeIndex } = props;

	return (
		<div className={css.sliderWrapper}>
			<input
				type="range"
				min="0"
				max={maxValue - 1}
				value={activeIndex}
				className={css.slider}
				id="myRange"
				onChange={e => {
					if (e.target.value) {
						setActiveIndex(+e.target.value);
					}
				}}
			/>
		</div>
	);
}
