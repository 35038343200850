// @flow

import produce from 'immer';
import _ from 'lodash';

import * as actions from 'client/constants/actions';

export type Action = {
	type: string,
	payload: any,
};

export type Settings = {
	data: any,
	isBusy: false,
};

const initialState: Settings = {
	isBusy: false,
	data: [],
};

export default function settingsReducer(state: Settings = initialState, { type, payload }: Action) {
	return produce(state, draft => {
		switch (type) {
			case actions.GET_SETTINGS.START:
				_.set(draft, 'isBusy', true);
				break;

			case actions.GET_SETTINGS.SUCCESS:
				_.set(draft, 'data', payload.item);
				_.set(draft, 'isBusy', false);
				break;

			case actions.GET_SETTINGS.FAIL:
				_.set(draft, 'isBusy', false);
				break;

			default:
				break;
		}
	});
}
