// @flow
import * as React from 'react';
import classNames from 'classnames';

import Socials from 'client/components/common/Socialls';
import yitLogo from 'assets/client/yit.png';
import * as css from './Footer.scss';

type Props = {
	className?: string,
};

const FooterMobile = ({ className }: Props) => {
	return (
		<footer className={classNames(css.footer, css.footerMobile, className)}>
			<span className={css.topTitle}>אנחנו ברשת, עיקבו אחרינו!</span>
			<Socials minified className={css.socials} />
			<div className={css.linkWrapper}>
				<a
					href="https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html"
					className={css.link}
					target="_blank"
					rel="noreferrer"
				>
					תנאי שימוש
				</a>
				<p>|</p>
				<a
					href="https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html"
					className={css.link}
					target="_blank"
					rel="noreferrer"
				>
					מדיניות פרטיות
				</a>
			</div>
			<div className={css.poweredByWrapper}>
				<p>powered by</p>
				<a href="https://yit.co.il/" target="_blank" rel="noreferrer">
					<img src={yitLogo} className={css.powered} alt="Powered by YIT" />
				</a>
			</div>
		</footer>
	);
};

FooterMobile.defaultProps = {
	className: '',
};

export { FooterMobile };
