// @flow
import { connect } from 'react-redux';
import _ from 'lodash';
import CoverPage from './CoverPage';

const mapState = (state: ClientStore) => ({
	covers: _.orderBy(_.get(state, 'catalog.data', []), 'timestamp'),
	currentCover: _.get(state, 'currentCover.data', null),
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(CoverPage);
